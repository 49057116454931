<template>
  <div class="p-grid crud-demo">
    <Dialog v-model:visible="editTicket" :style="{ width: '50vw' }" :modal="true">
      <TicketUI :id="editCard.id" @updated="list" @deleted="list" />
    </Dialog>
    <Loader v-model="loading" />
    <div class="p-col-12">
      <div class="card">
        <Toast />
        <Panel header="Seguimiento">
          <Toolbar class="p-mb-4">
            <template v-slot:left>
              <Button
                label="Listar"
                icon="pi pi-list"
                class="p-button-info p-mr-2"
                @click="list"
              />
              <Button
                label="Recargar"
                icon="pi pi-refresh"
                class="p-button-primary p-mr-2"
                @click="refresh"
              />
            </template>
            <template v-slot:right>
            <Button
              label="Exportar"
              icon="pi pi-upload"
              class="p-button-help"
              @click="exportCSV($event)"
            />
          </template>
          </Toolbar>
          <Fieldset :toggleable="true" legend="Parametros">
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-3">
                <label>Version</label>
                <Dropdown
                  v-model="entity.version"
                  :options="[{id: 1, name: 'Clientes'}, {id: 2, name: 'Usuarios'}]"
                  optionLabel="name"
                  optionValue="id"
                />
              </div>
              <div class="p-field p-col-3">
                <label>Tipo</label>
                <Dropdown
                  v-model="chartType"
                  :options="[{id: 'bar', name: 'Barras'}, {id: 'pie', name: 'Pastel'}]"
                  optionLabel="name"
                  optionValue="id"
                />
              </div>
              <div class="p-field p-col-3">
                <label for="sat">Fecha Inicio</label>
                <Calendar
                  dateFormat="dd/mm/yy"
                  v-model="params.initial_date"
                  :showIcon="true"
                />
              </div>
              <div class="p-field p-col-3">
                <label for="sat">Fecha Final</label>
                <Calendar
                  dateFormat="dd/mm/yy"
                  v-model="params.final_date"
                  :showIcon="true"
                />
              </div>
            </div>
          </Fieldset>
          <br />
          <Fieldset :toggleable="true" legend="Resultados">
            <div :style="chartStyle">
                <Chart :height="chartHeight" :width="chartWidth" ref="chart" :type="chartType" :data="basicData" :options="basicOptions" />
            </div>
          </Fieldset>
        </Panel>
      </div>
    </div>
  </div>
</template>

<script>
import { ErrorToast, randomColor } from "../../../utilities/General";
import Loader from "../../../components/general/Loader.vue";
import Session from "../../../mixins/sessionMixin";
import { TMTicket } from "../../../models/tickets/TMTicket";
import TicketUI from "../Aplicaciones/Ticket";

export default {
  mixins: [Session],
  data() {
    return {
      editTicket: false,
      chartType: 'bar',
      data: null,
      editCard: new TMTicket(this.session),
      basicData: {
                labels: [],
                datasets: []
            },
      chartHeight: 90,
      chartWidth: 300,
      chartStyle: null,
      basicOptions: {},
      entity: {
        version: 1
      },
      params: {
        initial_date: null,
        final_date: null
      },
      entities: [],
      users: [],
      tags: [],
      projects: [],
      projectsByCustomer: [],
      customers: [],
      filters: null,
      loading: false,
      loadingInputs: false
    };
  },
  watch: {
    chartType(newValue) {
      this.basicData = {
                labels: [],
                datasets: []
            };
      this.basicOptions = {};
      if (newValue == 'bar') {
        this.drawBar(this.data);
      } else if (newValue == 'pie') {
        this.drawPie(this.data);
      } 
      this.$refs.chart.refresh();
    }
  },
  components: { Loader, TicketUI },
  async mounted() {
    await this.refresh();
  },
  methods: {
    drawBar(data) {
      this.chartStyle = null;
      this.basicData.labels = data.map(x => {
                          return x.name
                        });
        this.basicData.datasets = [];
        this.basicData.datasets.push({
                        label: 'Tickets',
                        backgroundColor: '#42A5F5',
                        data: data.map(x => {
                          return x.count
                        }),
                        color: '#ffffff'
                    });
        this.basicOptions = {
           plugins: {
              legend: {
                  labels: {
                      color: '#ffffff'
                  }
              }
          },
          scales: {
              x: {
                  ticks: {
                      color: '#ffffff'
                  },
                  grid: {
                      color: '#ffffff'
                  }
              },
              y: {
                  ticks: {
                      color: '#ffffff'
                  },
                  grid: {
                      color: '#ffffff'
                  }
              }
          }
        }
    },
    drawPie(data) {
        this.chartStyle = {
          'max-height': '650px', 
          'max-width': '400px',
          'margin': '0 auto'
        };
        this.basicData.labels = data.map(x => {
                          return x.name
                        });
        this.basicData.datasets = [];
        /*eslint-disable */
        this.basicData.datasets.push({
                        backgroundColor: data.map(x => {
                          return randomColor();
                        }),
                        data: data.map(x => {
                          return x.count
                        })
                    });
        /*eslint-enable */

        this.basicOptions = {
           plugins: {
              legend: {
                  labels: {
                      color: '#ffffff'
                  }
              }
          }
        }
    },
    async list() {
      this.editTicket = false;
      this.loading = true;
      try {
        let data = await new TMTicket().graph(this.entity.version,this.params);
        this.data = data;
        if (this.chartType == 'bar') {
          this.drawBar(data);
        } else if (this.chartType == 'pie') {
          this.drawPie(data);
        }
        this.$refs.chart.refresh();
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    async refresh() {
      this.loading = true;
      try {
        this.entity = {
          version: 1
        };
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.table-header {
  display: flex;
  justify-content: space-between;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}

.p-chip.custom-chip {
  background: var(--primary-color);
  color: var(--primary-color-text);
}
.p-chip.tag-chip {
  background: #2c9663;
  color: var(--primary-color-text);
}
</style>
